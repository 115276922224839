import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import './index.css'
import shot_gun from './assets/shot_gun.svg'
import { useEffect, useState } from 'react';
import audi  from './assets/shotgun2.mp3'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from './Dashboard/Dashboard';
import OpenNews from './Dashboard/OpenNews';
import NavbarA from './Dashboard/NavbarA';
import Footer from './Dashboard/Footer';
import NavbarB from './Dashboard/NavbarB';
import GalleryImages from './Dashboard/GalleryImages';
const audioElement1 = new Audio(audi);

export default function App() {
  const [cursor, setcursor]=useState([])
  const [number, setnumber]=useState(0)
  var location = useLocation();
const changeMouse=(e)=>{
console.log(e.target.className)
if(e.target.tagName!=="svg" && e.target.tagName!=="path"){
  if(!(e.target.parentElement.className==="til"|| e.target.className.indexOf("navbar-toggler")!==-1|| e.target.parentElement.className.indexOf("navbar-toggler")!==-1|| e.target.parentElement.className.indexOf("ymaps")!==-1 || e.target.parentElement.className.indexOf("btnGreen")!==-1 || e.target.className==="poligons_item"  || e.target.parentElement.className==="membersInput" || e.target.className==="btn_img" || e.target.className.indexOf("pag_item")!==-1 || e.target.parentElement.className==="navbar-brand" || e.target.parentElement.parentElement.parentElement.parentElement.className.indexOf("ant-carousel")!==-1 || e.target.parentElement.parentElement.parentElement.parentElement.className.indexOf("slick-track")!==-1 || e.target.parentElement.parentElement.className==="faq_box" || e.target.parentElement.parentElement.className==="faq_questions" || e.target.parentElement.parentElement.className==="faq" || e.target.parentElement.className==="brand_text" || e.target.parentElement.parentElement.className==="brand_text" || e.target.className==="navbar-brand" || e.target.tagName==="A" || e.target.parentElement.tagName==="A" || e.target.parentElement.parentElement.tagName==="A" || e.target.className==="ymaps-2-1-79-events-pane ymaps-2-1-79-user-selection-none" || e.target.parentElement.parentElement.className.indexOf("modal")!==-1 || e.target.parentElement.className.indexOf("modal")!==-1)){
    var a=cursor
    let x = e.clientX;
    let y = e.clientY+window.scrollY;
  var con={opacity:1, zIndex:'4455', top:y-20,left:x-20}
  a.push(con)
  setcursor(a)
  setnumber(number+1)
  remove_shot(a.length-1)
  audioElement1.pause()
  audioElement1.play()
  
  }
}
}
const remove_shot=(key)=>{
 
setTimeout(() => {
  var a=cursor
  a[key].opacity=0
  a[key].zIndex='-4566'
  setcursor(a)
  setnumber(number-1)
  
}, 2000);

}
useEffect(()=>{
if(location.pathname==='/fotoalbom' || location.pathname.indexOf('/allnews')!==-1){
  window.scrollTo(0, 0);
}
},[location])
  return (
    <div className='big_boss'  onClick={(e)=>{changeMouse(e)}}>
    
      <Routes>
        <Route path="/" element={<Dashboard/>}/>
        <Route path="/allnews/:id" element={<>
        <NavbarB id="n"/>
        <OpenNews/>
        <Footer/>
        </>}/>
        <Route path="/fotoalbom" element={<>
        <NavbarB id="g"/>
        <GalleryImages/>
        <Footer/>
        </>}/>

      </Routes>
     

    {cursor.map((item, key)=>{
      return( <img key={key} style={{opacity: item.opacity,zIndex: item.zIndex,top:item.top,  left:item.left,}} className='shot_gun' src={shot_gun}/>)
    })}
  
    </div>
  )
}
