import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

const Index = () => {
  return   <BrowserRouter><App/></BrowserRouter>;
};

// Replace ReactDOM.render(...) with createRoot(...).render(...)
createRoot(document.getElementById('root')).render(<Index />);
