import React from 'react'
import { useTranslation } from 'react-i18next'
import partner_1 from '../assets/partner_1.png'
import partner_2 from '../assets/partner_2.png'
import partner_3 from '../assets/partner_3.png'
export default function Partners() {
    const {t}=useTranslation()
  return (
    <div className='partners'>
        <h1 className='title'>
{t("Hamkor tashkilotlar")}
        </h1>
        <div className='partners_box'>
        <div className='partners_item'>
                <img src={partner_1}/>
                <a href="#" target='_blank'>{t("O‘zbekiston ovchi va baliqchilar sporti birlashmasi")}</a>
            </div>
            <div className='partners_item'>
                <img src={partner_2}/>
                <a href="https://vatanparvar.uz/" target='_blank'>{t("”VATANPARVAR” tashkiloti")}</a>
            </div>
            <div className='partners_item'>
                <img src={partner_3}/>
                <a href="https://mudofaa.uz/" target='_blank'>{t("O‘zbekiston Respublikasi Mudofaa vazirligi")}</a>
            </div>
        </div>
    </div>
  )
}
