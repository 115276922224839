import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import faq from '../assets/faq.svg'
import cursor from '../assets/shot_gun.svg'

export default function FAQ() {
    const {t}=useTranslation()
    const [data,setdata]=useState([
        {
            questionUz:"“Kalibr” uzoq masofalarga o‘q otish klubining markaziy apparati qayerda joylashgan?",
            answerUz:"“Kalibr” uzoq masofalarga o‘q otish klubining markaziy apparati O‘zbekiston Respublikasi mudofaasiga ko‘maklashuvchi “Vatanparvar” tashkilotining Respublika texnik va amaliy sport turlari markazida joylashgan bo‘lib, ushbu Markaz bosh direktori “Kalibr” klubining rahbari hisoblanadi. “Kalibr” klubi a’zolari respublika bo‘ylab faoliyat yuritadi.",
            questionRu:"“Калибр” узоқ масофаларга ўқ отиш клубининг марказий аппарати қаерда жойлашган?",
            answerRu:"“Калибр” узоқ масофаларга ўқ отиш клубининг марказий аппарати Ўзбекистон Республикаси мудофаасига кўмаклашувчи “Ватанпарвар” ташкилотининг Республика техник ва амалий спорт турлари марказида жойлашган бўлиб, ушбу Марказ бош директори “Калибр” клубининг раҳбари ҳисобланади. “Калибр” клуби аъзолари республика бўйлаб фаолият юритади.",
            questionEn:"En What is the meaning of Lorem ipsum?",
            answerEn:"En 1.But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?",
            questionKrill:"Krill What is the meaning of Lorem ipsum?",
            answerKrill:"Krill 1.But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?",
    },
    {
        questionUz:"“Kalibr” klubiga istalgan fuqaro a’zo bo‘lishi mumkinmi?",
        answerUz:"Yo‘q. Klub Nizomiga muvofiq, unga faqatgina O‘zbekiston ovchi va baliqchilar sport birlashmasiga a’zo bo‘lgan (qurol olib yurishga haqli) fuqarolar qabul qilinadi.",
        questionRu:"“Калибр” клубига исталган фуқаро аъзо бўлиши мумкинми?",
        answerRu:"Йўқ. Клуб Низомига мувофиқ, унга фақатгина Ўзбекистон овчи ва балиқчилар спорт бирлашмасига аъзо бўлган (қурол олиб юришга ҳақли) фуқаролар қабул қилинади.",
        questionEn:"En What is the origins of Lorem ipsum Dolor sit?",
        answerEn:"En 2.But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?",
        questionKrill:"Krill What is the origins of Lorem ipsum Dolor sit?",
        answerKrill:"Krill 2.But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?",

},
{

    questionUz:"Klubga a’zo bo‘lish tartibi qanday?",
    answerUz:"A’zo bo‘lishni istagan fuqarolar “Vatanparvar” tashkilotining Qoraqalpog‘iston Respublikasi va viloyatlardagi texnik va amaliy turlari markazlariga murojaat qilishlari mumkin. Toshkent shahrida istiqomat qilayotgan fuqarolar to‘g‘ridan-to‘g‘ri Respublika texnik va amaliy sport turlari markaziga murojaat qilishlari lozim.",
    questionRu:"Клубга аъзо бўлиш тартиби қандай?",
    answerRu:"Аъзо бўлишни истаган фуқаролар “Ватанпарвар” ташкилотининг Қорақалпоғистон Республикаси ва вилоятлардаги техник ва амалий турлари марказларига мурожаат қилишлари мумкин. Тошкент шаҳрида истиқомат қилаётган фуқаролар тўғридан-тўғри Республика техник ва амалий спорт турлари марказига мурожаат қилишлари лозим.",
    questionEn:"En What is the most used version?",
    answerEn:"En 3.But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?",
    questionKrill:"Krill What is the most used version?",
    answerKrill:"Krill 3.But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?",
},
{
    questionUz:"“Kalibr” klubiga a’zo bo‘lish uchun qanday hujjatlar topshiriladi?",
    answerUz:"Klubga qabul qilish fuqarolarning arizalari (ixtiyoriylik) asosida amalga oshiriladi. Klubga a’zo bo‘lish chog‘ida fuqarolar arizasiga O‘zbekiston Respublikasi fuqarosi pasporti yoki identifikatsiyalovchi ID-kartasi (nusxasi olinib, asli qaytariladi), ovchilik guvohnomasi, IIB tomonidan berilgan o‘qotar qurolni olib yurish va saqlash huquqini beruvchi ruxsatnoma, kirish va a’zolik badalini to‘laganlik to‘g‘risidagi hujjatlarni ilova qilishlari lozim.",
    questionRu:"“Калибр” клубига аъзо бўлиш учун қандай ҳужжатлар топширилади?",
    answerRu:"Клубга қабул қилиш фуқароларнинг аризалари (ихтиёрийлик) асосида амалга оширилади. Клубга аъзо бўлиш чоғида фуқаролар аризасига Ўзбекистон Республикаси фуқароси паспорти ёки идентификатсияловчи ИД-картаси (нусхаси олиниб, асли қайтарилади), овчилик гувоҳномаси, ИИБ томонидан берилган ўқотар қуролни олиб юриш ва сақлаш ҳуқуқини берувчи рухсатнома, кириш ва аъзолик бадалини тўлаганлик тўғрисидаги ҳужжатларни илова қилишлари лозим.",
    questionEn:"En What is the original text of Lorem Ipsum Dolor sit amet?",
    answerEn:"En 4.But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?",
    questionKrill:"Krill What is the original text of Lorem Ipsum Dolor sit amet?",
    answerKrill:"Krill 4.But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?",
},
{
    questionUz:"“Kalibr” klubi a’zolari a’zolik badali to‘laydilarmi?",
    answerUz:"Ha. Klub Nizomiga muvofiq, a’zolar tomonidan quyidagi miqdorda badallar to‘lanadi: kirish badali – badalni amalga oshirish kunida belgilangan bazaviy hisoblash miqdorining bir barobari; a’zolik badali – har yili, badalni amalga oshirish kunida belgilangan bazaviy hisoblash miqdorining bir barobari; qayta a’zo bo‘lganlar – a’zo bo‘lgan kunidan qat’iy nazar yillik a’zolik badali to‘lovi to‘liq miqdorda amalga oshiriladi.",
    questionRu:"“Калибр” клуби аъзолари аъзолик бадали тўлайдиларми?",
    answerRu:"Ҳа. Клуб Низомига мувофиқ, аъзолар томонидан қуйидаги миқдорда бадаллар тўланади: кириш бадали – бадални амалга ошириш кунида белгиланган базавий ҳисоблаш миқдорининг бир баробари; аъзолик бадали – ҳар йили, бадални амалга ошириш кунида белгиланган базавий ҳисоблаш миқдорининг бир баробари; қайта аъзо бўлганлар – аъзо бўлган кунидан қатъи назар йиллик аъзолик бадали тўлови тўлиқ миқдорда амалга оширилади. ",
    questionEn:"En What is the meaning of Lorem ipsum?",
    answerEn:"En 5.But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?",
    questionKrill:"Krill What is the meaning of Lorem ipsum?",
    answerKrill:"Krill 5.But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?",
},
{
    questionUz:"“Kalibr” klubi a’zolari qaerda mashg‘ulot o‘taydilar?",
    answerUz:"Klub a’zolarining mashg‘ulotlari va ular o‘rtasidagi musobaqalar fuqarolar xavfsizligi inobatga olingan holda, asosan, harbiy okruglarning Chirchiq (Toshkent v.), Andijon (Andijon v.), Forish (Jizzax v.), Yangiariq (Xorazm v.), Nuriston (Qashqadaryo v.) va Termiz (Surxondaryo v.) dala o‘quv maydonlarida o‘tkaziladi.",
    questionRu:"“Калибр” клуби аъзолари қаерда машғулот ўтайдилар?",
    answerRu:"Клуб аъзоларининг машғулотлари ва улар ўртасидаги мусобақалар фуқаролар хавфсизлиги инобатга олинган ҳолда, асосан, ҳарбий округларнинг Чирчиқ (Тошкент в.), Андижон (Андижон в.), Фориш (Жиззах в.), Янгиариқ (Хоразм в.), Нуристон (Қашқадарё в.) ва Термиз (Сурхондарё в.) дала ўқув майдонларида ўтказилади.",
    questionEn:"En What is the original text of Lorem Ipsum Dolor sit amet?",
    answerEn:"En 6.But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?",
    questionKrill:"Krill What is the original text of Lorem Ipsum Dolor sit amet?",
    answerKrill:"Krill 6.But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?",
},{
    questionUz:"“Kalibr” klubi tadbirlari davomida tabiatga ziyon yetishi mumkinmi?",
    answerUz:"Bunga aslo yo‘l qo‘yilmaydi. O‘zbekiston Respublikasi mudofaasiga ko‘maklashuvchi “Vatanparvar” tashkiloti, O‘zbekiston ovchi va baliqchilar sport birlashmasi hamda O‘zbekiston Respublikasi Mudofaa vazirligi tomonidan imzolangan o‘zaro hamkorlik memorandumiga ko‘ra, Ona tabiat va hayvonot dunyosiga ziyon yetkazilishiga yo‘l qo‘ymaslikka qaratilgan tadbirlarni tashkil etish klubning asosiy maqsadlaridan hisoblanadi.",
    questionRu:"“Калибр” клуби тадбирлари давомида табиатга зиён етиши мумкинми?",
    answerRu:"Бунга асло йўл қўйилмайди. Ўзбекистон Республикаси мудофаасига кўмаклашувчи “Ватанпарвар” ташкилоти, Ўзбекистон овчи ва балиқчилар спорт бирлашмаси ҳамда Ўзбекистон Республикаси Мудофаа вазирлиги томонидан имзоланган ўзаро ҳамкорлик меморандумига кўра, Она табиат ва ҳайвонот дунёсига зиён етказилишига йўл қўймасликка қаратилган тадбирларни ташкил этиш клубнинг асосий мақсадларидан ҳисобланади.",
    questionEn:"En What is the original text of Lorem Ipsum Dolor sit amet?",
    answerEn:"En 6.But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?",
    questionKrill:"Krill What is the original text of Lorem Ipsum Dolor sit amet?",
    answerKrill:"Krill 6.But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?",
},
])
const [number, setnumber]=useState(0)
  return (
    <div className='faq'>
       
        <h1 className='title'>{t("FAQ")}</h1>
        <div className='faq_box'>
        <img className='faq_img' src={faq}/>
             <div className='faq_questions'>
                {data.map((item,key)=>{ return( <div key={key} className={`question_item ${number===key?"question_active":""}`} onClick={()=>{setnumber(key)}}><span>{item[`question${t("lang")}`]}</span><img src={cursor} /> </div>) })}
               </div>
             <div className='faq_answer'>
                <div>
                {data[number][`answer${t("lang")}`]}
                </div>
             </div>
        </div>
    </div>
  )
}
