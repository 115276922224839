import { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import "./assets/news.css";
import {Row, Col} from 'antd'
import { AiFillRightCircle, AiOutlineEye } from 'react-icons/ai';
import { FiCalendar } from 'react-icons/fi';
import axios from 'axios';
import { api } from '../host/Host';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

export function News() {
    const { t } = useTranslation();

    const [data, setData] = useState(null)
    const [number, setNumber] = useState(0)
    const [start, setStart] = useState(0)
    
    const getNews = async () => {
        await axios.get(`${api}/GetApi/GetNews/`)
            .then((res) => {
              var b=[]
              var i=0
              var g=[]
              var gt=res.data
              gt.map((item, key)=>{
                var x=item
                item.newsDate=x.newsDate.substring(8, 10)+'.'+x.newsDate.substring(5, 7)+'.'+x.newsDate.substring(0, 4)
                g.push(item)
                i++
               
                if(i===6 || key===gt.length-1){
                    b.push(g)
                    i=0
                    g=[]
                }
                })  
               
                    
              setData(b)
              console.log(b)
                })
            .catch(e => console.log(e))
    }
    useEffect(() => {
        getNews();
     
    }, [])
    return (

        <div className="news">
              <h1 className='title'>
{t("Yangiliklar")}
        </h1>
            <div className="container">
               
              <Row className='news_row' style={{ width:'100%'}}>

{
    data!==null?data[number].map((item, index) =>{
        return( <Col key={index} style={{padding:'10px'}} lg={8} md={12} sm={24}>

        <Link to={"/allnews/" + item.id} className="news_card" key={index}>
            <div className='news_card_img'>
              <img src={`${api}/Files/News/${item.titleImage}`}/>
            </div>
           <h1>{item[`title${t("lang")}`]}</h1>
           <p><span><FiCalendar/></span>{item.newsDate.substring(0, 10)}</p>
                  </Link>
               </Col>)
    } ):<></>
  }
</Row>
{data!==null?<div className='paginations'>

{start>0?<div onClick={()=>{setNumber(number-1); setStart(start-1)}} className='pag_item left_arrow'><IoIosArrowBack/></div>:<></>}
    {data.slice(0,6).length>1?data.slice(0,6).map((item,key)=>{
        return( <div key={key} className={`pag_item ${number===key+start?"active_page":""}`} onClick={()=>{setNumber(key+start)}}>{key+1+start}</div>)
    }):<></>}
{start<data.length-6?<div onClick={()=>{setNumber(number+1); setStart(start+1)}} className='pag_item left_arrow'><IoIosArrowForward/></div>:<></>}


</div>:<></>}

            </div>
        </div>
    );
}

