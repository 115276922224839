import React from 'react'
import { useTranslation } from 'react-i18next'

export default function Main() {
  const {t} = useTranslation()
  return (
    <div className='main'>
     
     <div className='main_text'>
          <h1 dangerouslySetInnerHTML={{__html:t("brand")}}/>
        </div>
        <div className='main_img'></div>
      
    </div>
  )
}
