import { Container, Nav, NavDropdown, Navbar, OverlayTrigger, Tooltip } from "react-bootstrap";
import logo from '../assets/logo.svg'
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import i18n from "../locales/i18next";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { useStore } from "../store/Store";
export default function NavbarB({id}) {
  const [cookie, setCookie]=useCookies()
  const setnav=useStore(state=>state.setnav)
  const [til, settil] = useState("o'z");
  const {t} =useTranslation()
  useEffect(()=>{
    i18n.changeLanguage("o'z")
  },[])
 
  const changeLanguageA=()=>{
    if(til==="o'z"){
      settil("ўз")
      i18n.changeLanguage("ўз")
      setCookie("lang", 'ўз')
    }
    if(til==='ўз'){
      settil("ру")
      i18n.changeLanguage("ру")
      setCookie("lang", "ру")
    } 
    if(til==='ру'){
      settil("en")
      i18n.changeLanguage("en")
      setCookie("lang", 'en')
      
    }
    if(til==='en'){
      settil("o'z")
      i18n.changeLanguage("o'z")
      setCookie("lang", "o'z")
    }
   
   
   }
  return (
    <div className='navbar_dash'>
         <Navbar bg="light"  expand="lg">
      <Container fluid>
        <Navbar.Brand>
            <NavLink  to="/" className="navbar_brand">
                <img src={logo}/>
                <p className="brand_text" dangerouslySetInnerHTML={{__html:t("brand")}}/>
            </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink onClick={()=>{setnav("/")}} to="/" className="link">{t("Biz haqimizda")}</NavLink>
            <NavLink onClick={()=>{setnav("/")}} to="/" className={`link ${id==="n"?"active_link":""}`}>{t("Yangiliklar")}</NavLink>
            <NavLink onClick={()=>{setnav("/")}} to="/"  className="link">{t("Statistika")}</NavLink>
            <NavLink onClick={()=>{setnav("/")}} to="/"  className={`link ${id==="g"?"active_link":""}`}>{t("Galereya")}</NavLink>
            <NavLink onClick={()=>{setnav("/")}} to="/"  className="link">{t("ONLINE TIR")}</NavLink>
            <NavLink onClick={()=>{setnav("/")}} to="/"  className="link faqLink">
            <OverlayTrigger
      placement="bottom"
  //  show={true}
      delay={{ show: 0, hide: 0 }}
      overlay={  <Tooltip id="button-tooltip">
     {t("faqtitle")}
    </Tooltip>}
    >
    <span> {t("FAQ")}</span>
    </OverlayTrigger>
              </NavLink>
            <NavLink onClick={()=>{setnav("/")}} to="/"  className="link">{t("Lokatsiya")}</NavLink>
           </Nav>
          <div  className="til">
            <p onClick={changeLanguageA}>{til}</p>
            
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  )
}
