import React, { useEffect, useRef } from 'react'
import NavbarA from './NavbarA'
import Main from './Main'
import About from './About'
import Partners from './Partners'
import Stattistic from './Stattistic'
import Tir from './Tir'
import { Xarita } from './Xarita'
import Gallery from './Gallery'
import Footer from './Footer'
import FAQ from './FAQ'
import { News } from './News'
import { useStore } from '../store/Store'

export default function Dashboard() {
  const about = useRef(null);
  const news = useRef(null);
  const satatistics = useRef(null);
  const gallery = useRef(null);
  const tir = useRef(null);
  const faq = useRef(null);
  const location = useRef(null);
  const setnav=useStore(state=>state.setnav)
  const handleScroll = () => {
      const aboutRect = about.current.getBoundingClientRect();
      const newsRect = news.current.getBoundingClientRect();
      const satatisticsRect = satatistics.current.getBoundingClientRect();
      const galleryRect = gallery.current.getBoundingClientRect();
      const tirRect = tir.current.getBoundingClientRect();
      const faqRect = faq.current.getBoundingClientRect();
      const locationRect = location.current.getBoundingClientRect();

    
    const aboutBottomVisible = (aboutRect.bottom+500 <= window.innerHeight && aboutRect.bottom>=0);
    const newsBottomVisible = (newsRect.bottom+500 <= window.innerHeight && newsRect.bottom>=0);
    const satatisticsBottomVisible = (satatisticsRect.bottom+500 <= window.innerHeight && satatisticsRect.bottom>=0);
    const galleryBottomVisible = (galleryRect.bottom+500 <= window.innerHeight && galleryRect.bottom>=0);
    const tirBottomVisible = (tirRect.bottom+500 <= window.innerHeight && tirRect.bottom>=0);
    const faqBottomVisible = (faqRect.bottom+500 <= window.innerHeight && faqRect.bottom>=0);
    const locationBottomVisible = (locationRect.bottom+500 <= window.innerHeight && locationRect.bottom>=0);
    
    if (aboutBottomVisible) {
      setnav("about")
      
     }
     if (newsBottomVisible) {
      setnav("news")
     
     }
     if (satatisticsBottomVisible) {
      setnav("satatistics")
     }
     if (galleryBottomVisible) {
      setnav("gallery")
     }
     if (tirBottomVisible) {
      setnav("tir")
     }
   
     if (faqBottomVisible) {
      setnav("faq")
     }
     if (locationBottomVisible) {
      setnav("location")
     }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div>
        <NavbarA/>
        <div className='id_loc' id=""></div>
        <Main/>
        
        <div ref={about} className='id_loc' id="about"></div>
        <About/>
        <div ref={news} className='id_loc' id="news"></div>
        <News/>
        
        <div ref={satatistics}  className='id_loc' id="satatistics"></div>
        <Stattistic/>
        
        <div ref={gallery} className='id_loc' id="gallery"></div>
        <Gallery/>
        
        
        <div ref={tir} className='id_loc' id="tir"></div>
        <Tir/>
        
        <div className='id_loc' id="partners"></div>
        <Partners/>

        <div ref={faq} className='id_loc' id="faq"></div>
        <FAQ/>
        
        <div ref={location} className='id_loc' id="location"></div>
        <Xarita/>
        

        <Footer/>
    </div>
  )
}
