import { Container, Nav, NavDropdown, Navbar, OverlayTrigger, Tooltip } from "react-bootstrap";
import logo from '../assets/logo.svg'
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import i18n from "../locales/i18next";
import { useTranslation } from "react-i18next";
// import uz from '../assets/uz.png'
// import ru from '../assets/ru.png'
// import en from '../assets/en.webp'
import { useStore } from "../store/Store";
export default function NavbarA() {
  const [cookie, setCookie]=useCookies()
  const [til, settil] = useState("o'z");
  const nav=useStore(state=>state.nav)
  const setnav=useStore(state=>state.setnav)
  const {t} =useTranslation()
  useEffect(()=>{
    i18n.changeLanguage("o'z")
  },[])
//  const imgs={
//   "o'z":uz,
//   'ўз':uz,
//   'ру':ru,
//   'en':en,
//  }
  const changeLanguageA=()=>{
    if(til==="o'z"){
      settil("ўз")
      i18n.changeLanguage("ўз")
      setCookie("lang", 'ўз')
    }
    if(til==='ўз'){
      settil("ру")
      i18n.changeLanguage("ру")
      setCookie("lang", "ру")
    } 
    if(til==='ру'){
      settil("en")
      i18n.changeLanguage("en")
      setCookie("lang", 'en')
      
    }
    if(til==='en'){
      settil("o'z")
      i18n.changeLanguage("o'z")
      setCookie("lang", "o'z")
    }
   
   
   }
  return (
    <div className='navbar_dash'>
         <Navbar bg="light"  expand="lg">
      <Container fluid>
        <Navbar.Brand href="#">
            <div className="navbar_brand">
                <img src={logo}/>
                <p className="brand_text" dangerouslySetInnerHTML={{__html:t("brand")}}/>
            </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={()=>{setnav("about")}} href="#about" className={`link aboutLink ${nav==="about"?"active_link":''}`}>{t("Biz haqimizda")}</Nav.Link>
            <Nav.Link onClick={()=>{setnav("news")}} href="#news" className={`link ${nav==="news"?"active_link":''}`}>{t("Yangiliklar")}</Nav.Link>
            <Nav.Link onClick={()=>{setnav("satatistics")}} href="#satatistics"  className={`link ${nav==="satatistics"?"active_link":''}`}>{t("Statistika")}</Nav.Link>
            <Nav.Link onClick={()=>{setnav("gallery")}} href="#gallery"  className={`link ${nav==="gallery"?"active_link":''}`}>{t("Galereya")}</Nav.Link>
            <Nav.Link onClick={()=>{setnav("tir")}} href="#tir"  className={`link ${nav==="tir"?"active_link":''}`}>{t("ONLINE TIR")}</Nav.Link>
            <Nav.Link onClick={()=>{setnav("faq")}} href="#faq"  className={`link ${nav==="faq"?"active_link":''} faqLink`}>
            <OverlayTrigger
      placement="bottom"
  //  show={true}
      delay={{ show: 0, hide: 0 }}
      overlay={  <Tooltip id="button-tooltip">
     {t("faqtitle")}
    </Tooltip>}
    >
    <span> {t("FAQ")}</span>
    </OverlayTrigger>
              </Nav.Link>
            <Nav.Link href="#location"  className={`link ${nav==="location"?"active_link":''}`}>{t("Lokatsiya")}</Nav.Link>
           </Nav>
          <div  className="til">
            <p onClick={changeLanguageA}>{til}</p>
            {/* <img src={imgs[til]}/> */}
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  )
}
