import React, { useEffect, useState } from 'react'
import bird from '../assets/bird.gif'
import anim from '../assets/anim.gif'
import quyon from '../assets/quyon.gif'
import replay from '../assets/replay.svg'
import bird_white from '../assets/bird_white.svg'
import animal_white from '../assets/animal_white.svg'
import quyon_white from '../assets/quyon_white.svg'
import playimg from '../assets/play.svg'
import shotimg from '../assets/shot.svg'
import shot_disableimg from '../assets/shot_disable.svg'
import { useTranslation } from 'react-i18next'
export default function Tir() {
const [play, setPlay]=useState(false)
const [finish, setfinish]=useState(false)
const [deadanimal, setdeadanimal]=useState(0)
const [deadbird, setdeadbird]=useState(0)
const [deadquyon, setdeadquyon]=useState(0)
const [number, setnumber]=useState(0)

const [shot, setshot]=useState(0)
const [addNum, setaddNum]=useState([])
const [animalcoor, setanimalcoor]=useState(JSON.stringify([]))
const [birdcoor, setbirdcoor]=useState(JSON.stringify([]))
const [quyoncoor, setquyoncoor]=useState(JSON.stringify([]))
const {t}=useTranslation()
useEffect(()=>{
  
    if(play){
      if(shot>=5){
        setanimalcoor(JSON.stringify([]))
        setbirdcoor(JSON.stringify([]))
        setquyoncoor(JSON.stringify([]))
        setfinish(true)
       }else{
        if(animalcoor===JSON.stringify([]) && quyoncoor===JSON.stringify([]) && birdcoor===JSON.stringify([])){
          setTimeout(() => {createAnimal()}, 200)
        }
       }
      }
      }, [play, shot, animalcoor, birdcoor, quyoncoor])
const createAnimal=()=>{
  var x=getRandomInt(1,4)
  if(x===1){
var h=[{
  zIndex:4,
  top:28,
  scale:9,
},
{
  zIndex:9,
  top:43,
  scale:11,
},
{
  zIndex:12,
  top:55,
  scale:13,
}
]
var t=getRandomInt(0,3)
    if(getRandomInt(1,3)===1){
      var a={data:[-10, h[t].top], zIndex:h[t].zIndex,scale:h[t].scale, transform:0}
     }else{
      var a={data:[110, h[t].top], zIndex:h[t].zIndex,scale:h[t].scale, transform:180}
     }

    if(typeof a!=="object"){
      a=[]
    }
     setanimalcoor(JSON.stringify(a))
    
    moveAnimal(Number(document.querySelector('.deadanimal').innerHTML))
      
  }else{
    if(x===3){

      if(getRandomInt(1,3)===1){
        var a={data:[-10, getRandomInt(50, 75)], transform:0}
       }else{
        var a={data:[110, getRandomInt(50, 75)], transform:180}
       }
   
      if(typeof a!=="object"){
        a=[]
      }
       setbirdcoor(JSON.stringify(a))
      
      moveBird(Number(document.querySelector('.deadbird').innerHTML))
        
    }else{
      var h=[{
        zIndex:4,
        top:36,
        scale:6,
      },
      {
        zIndex:9,
        top:53,
        scale:7,
      },
      {
        zIndex:12,
        top:65,
        scale:8,
      }
      ]
      var t=getRandomInt(0,3)
      if(getRandomInt(1,3)===1){
        var a={data:[-10,h[t].top], t:true, zIndex:h[t].zIndex,scale:h[t].scale, transform:180}
       }else{
        var a={data:[110,h[t].top], t:true, zIndex:h[t].zIndex,scale:h[t].scale, t:false, transform:0}
       }
       
      if(typeof a!=="object"){
        a=[]
      }
       setquyoncoor(JSON.stringify(a))
      
      moveQuyon(Number(document.querySelector('.deadquyon').innerHTML))
    }
  }

}
const moveAnimal=(num)=>{

  setTimeout(() => {
    var g=JSON.parse(document.querySelector('.animalcoor').innerHTML)
    var n=Number(document.querySelector('.deadanimal').innerHTML)
   
    if(g.length!==0 && num===n){
      if(g.data[0]===-10){
        var a={data:[110, g.data[1]], zIndex:g.zIndex,scale:g.scale, transform:0}
      }else{
        var a={data:[-10, g.data[1]], zIndex:g.zIndex,scale:g.scale, transform:180}
      }
      if(typeof a!=="object"){
        a=[]
      }
      setanimalcoor(JSON.stringify(a))
      
      setTimeout(() => {
        var g=JSON.parse(document.querySelector('.animalcoor').innerHTML)
    var n=Number(document.querySelector('.deadanimal').innerHTML)
        if(g.length!==0 && num===n){
        setanimalcoor(JSON.stringify([]))
        }
      }, 5400);
    }
   }, 120);  
}
const moveBird=(num)=>{
  
  setTimeout(() => {
    var g=JSON.parse(document.querySelector('.birdcoor').innerHTML)
     var n=Number(document.querySelector('.deadbird').innerHTML)

     if(g.length!==0 && num===n){
    if(g.data[0]===-10){
      var a={data:[110, -5], transform:0}
    }else{
      var a={data:[-10, -5], transform:180}
    }
    if(typeof a!=="object"){
      a=[]
    }
    setbirdcoor(JSON.stringify(a))
    
    setTimeout(() => {
      var g=JSON.parse(document.querySelector('.birdcoor').innerHTML)
     var n=Number(document.querySelector('.deadbird').innerHTML)
      if(g.length!==0 && num===n){
      setbirdcoor(JSON.stringify([]))
      }
    }, 4400);}
  }, 120);  
}
const moveQuyon=(num)=>{
  
  setTimeout(() => {
    var g=JSON.parse(document.querySelector('.quyoncoor').innerHTML)
     var n=Number(document.querySelector('.deadquyon').innerHTML)

     if(g.length!==0 && num===n){
      if(g.t){
        var a={data:[g.data[0]+10, g.data[1]-10],t:true, zIndex:g.zIndex,scale:g.scale, transform:180}
      }else{
        var a={data:[g.data[0]-10, g.data[1]-10],t:false, zIndex:g.zIndex,scale:g.scale, transform:0}
      }
      if(typeof a!=="object"){
        a=[]
      }
      setquyoncoor(JSON.stringify(a))
      
      setTimeout(() => {
        var g=JSON.parse(document.querySelector('.quyoncoor').innerHTML)
         var n=Number(document.querySelector('.deadquyon').innerHTML)

         if(g.length!==0 && num===n){
        if(g.t){
          var a={data:[g.data[0]+10,  g.data[1]+10], t:true, zIndex:g.zIndex,scale:g.scale, transform:180}
          if(g.data[0]+10<110){
            setTimeout(() => {
              var g=JSON.parse(document.querySelector('.quyoncoor').innerHTML)
              var n=Number(document.querySelector('.deadquyon').innerHTML)
              if(g.length!==0 && num===n){
              moveQuyon(Number(document.querySelector('.deadquyon').innerHTML))}
            },580)
           }else{
            setTimeout(() => {
              var g=JSON.parse(document.querySelector('.quyoncoor').innerHTML)
              var n=Number(document.querySelector('.deadquyon').innerHTML)
              if(g.length!==0 && num===n){
                setquyoncoor(JSON.stringify([]))
              }
            }, 580);
           }
        }else{
          var a={data:[g.data[0]-10, g.data[1]+10], t:false, zIndex:g.zIndex,scale:g.scale, transform:0}
          if(g.data[0]+10>-10){
            setTimeout(() => {
              var g=JSON.parse(document.querySelector('.quyoncoor').innerHTML)
              var n=Number(document.querySelector('.deadquyon').innerHTML)
              if(g.length!==0 && num===n){
              moveQuyon(Number(document.querySelector('.deadquyon').innerHTML))}
            },580)
          }else{
            setTimeout(() => {
              var g=JSON.parse(document.querySelector('.quyoncoor').innerHTML)
              var n=Number(document.querySelector('.deadquyon').innerHTML)
              if(g.length!==0 && num===n){
              setquyoncoor(JSON.stringify([]))
              }
            }, 580);}
           }
        }
        if(typeof a!=="object"){
          a=[]
        }
        setquyoncoor(JSON.stringify(a))
        
       
      }, 600);  
    }
   
  }, 20);  

}
function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }
const killAnimal=(t,e)=>{
 
  let x = e.clientX-20;
  let y = e.clientY+window.scrollY - 60;
  var con={top:y,left:x, show:true}
  var n=null
  var f=addNum
  var gf=f.filter(it=>it.top===y && it.left===x && it.show)
  if(gf.length==0){
    f.push(con)
    n=f.length
    setaddNum(f)
    setTimeout(() => {
      var f=addNum
      console.log(f, n)
      f[n-1].show=false
      setaddNum(f)
    setnumber(Number(document.querySelector(".number").innerHTML)+1)
    },100)
  }
  
  if(t===1){
    setanimalcoor(JSON.stringify([]))
    setdeadanimal(deadanimal+1)
   
    
  }
  if(t===3){
    setbirdcoor(JSON.stringify([]))
    setdeadbird(deadbird+1)
    
  }
  if(t===2){
    setquyoncoor(JSON.stringify([]))
    setdeadquyon(deadquyon+1)
    
  }

}
const addShot=(e)=>{
  
  if(e.target.className==="rep_img"){
    replayGame()

  }else{
    if(document.querySelector('.play').innerHTML==='true'){
      setshot(Number(document.querySelector('.shot').innerHTML)+1)
    }
  }
  
  if(e.target.parentElement.className==="img img_animal"){
    killAnimal(1,e)
  }
  
  if(e.target.parentElement.className==="img img_bird"){
    killAnimal(3,e)
}
  
  if(e.target.parentElement.className==="img img_quyon"){
    killAnimal(2,e)
   }
  
}
const replayGame=()=>{
  setshot(0)
  setdeadanimal(0)
  setdeadbird(0)
  setdeadquyon(0)
  setfinish(false)
}
  return (
    <>
      {addNum.map((item, key)=>{return(<div className={`addNum ${!item.show?"dis_addNum":""}`} key={key} style={{top:item.top,left:item.left}}>+1</div>)})}
    
    <div className="tir" onClick={(e)=> {addShot(e)}}>
      <div   onClick={(e)=> {addShot(e)}} className='grass_1 grass'></div>
      <div   onClick={(e)=> {addShot(e)}} className='grass_2 grass'></div>
      <div   onClick={(e)=> {addShot(e)}} className='grass_3 grass'></div>
     
        {/* <button onClick={()=>{setanimal(animal+1)}}>Animal</button> */}
       
       {!play? 
       <>
        <div className='play_box_back'></div>
       <div className='play_box'>
       
        <a href="#tir" className='play_btn' onClick={()=>{setPlay(true)}}><img src={playimg}/></a>
        <h1>{t("ONLINE TIR")}</h1>
        </div>
        </>
        :
        <div className='gaming_head'>
           <h1>{t("ONLINE TIR")}</h1>
          <div className='shots'> <img src={shot<1?shotimg:shot_disableimg}/>
        <img src={shot<2?shotimg:shot_disableimg}/>
        <img src={shot<3?shotimg:shot_disableimg}/>
        <img src={shot<4?shotimg:shot_disableimg}/>
        <img src={shot<5?shotimg:shot_disableimg}/></div>
      
      </div>}
       
       <div className={`result_game ${finish?"result_game_open":''}`}>

        <div className='result_game_box'>
        
          <h1>{t("O'yin tugadi")}</h1>
          <a href="#tir" className='replay' ><img className='rep_img' src={replay}/></a>
          <div className='result_game_imgs'>
          <div className='game_item'>
              <img src={animal_white}/>
              <p>{deadanimal}</p>
            </div>
            <div className='game_item'>
              <img src={bird_white}/>
              <p>{deadbird}</p>
            </div>
            <div className='game_item'>
              <img src={quyon_white}/>
              <p>{deadquyon}</p>
            </div>
          </div>
        </div>
       </div>
     <div className='gaming'  onClick={(e)=> {addShot(e)}}>


     {animalcoor!==JSON.stringify([])?  <div onClick={(e)=>{ addShot(e)}} className={`img img_animal`} style={{transform:`rotateY(${JSON.parse(animalcoor).transform}deg)`,width:`${JSON.parse(animalcoor).scale}vw`,height:`${JSON.parse(animalcoor).scale}vw`, backgroundImage:`url(${anim})`, top:`${JSON.parse(animalcoor).data[1]}%`, zIndex:JSON.parse(animalcoor).zIndex, left:`${JSON.parse(animalcoor).data[0]}%`}}><div onClick={(e)=>{ addShot(e); }}></div></div>:<></>}
     {birdcoor!==JSON.stringify([])?  <div onClick={(e)=>{ addShot(e)}} className={`img img_bird`} style={{transform:`rotateY(${JSON.parse(birdcoor).transform}deg)`, backgroundImage:`url(${bird})`, top:`${JSON.parse(birdcoor).data[1]}%`, left:`${JSON.parse(birdcoor).data[0]}%`}}><div onClick={(e)=>{ addShot(e); }}></div></div>:<></>}
   {quyoncoor!==JSON.stringify([])?  <div onClick={(e)=>{ addShot(e)}} className={`img img_quyon`} style={{transform:`rotateY(${JSON.parse(quyoncoor).transform}deg)`,width:`${JSON.parse(quyoncoor).scale}vw`,height:`${JSON.parse(quyoncoor).scale}vw`, backgroundImage:`url(${quyon})`, top:`${JSON.parse(quyoncoor).data[1]}%`,zIndex:JSON.parse(quyoncoor).zIndex, left:`${JSON.parse(quyoncoor).data[0]}%`}}><div onClick={(e)=>{ addShot(e); }}></div></div>:<></>}
    
     </div>
   
     
 <p style={{display:'none'}} className='animalcoor'>{animalcoor}</p>
 <p style={{display:'none'}} className='birdcoor'>{birdcoor}</p>
 <p style={{display:'none'}} className='quyoncoor'>{quyoncoor}</p>
 <p style={{display:'none'}} className='deadanimal'>{deadanimal}</p>
 <p style={{display:'none'}} className='deadbird'>{deadbird}</p>
 <p style={{display:'none'}} className='deadquyon'>{deadquyon}</p>
 <p style={{display:'none'}} className='shot'>{shot}</p>
 <p style={{display:'none'}} className='play'>{`${play}`}</p>
 <p style={{display:'none'}} className='number'>{`${number}`}</p>
    </div>
    </>
  )
}
