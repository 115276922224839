import React, { useState, useEffect, useRef } from "react";
import {
  YMaps,
  Map,
  Clusterer,
  Placemark,
  ZoomControl,

} from "react-yandex-maps";
import "../Xarita.css"

import {HiOutlineLocationMarker} from "react-icons/hi";
    import { FaBuilding} from 'react-icons/fa'
    import { BsTelephoneFill,} from 'react-icons/bs'

    import { IoIosCloseCircle,} from 'react-icons/io'

    
import { useTranslation } from 'react-i18next';
import { Modal } from "react-bootstrap";
import axios from "axios";
import { api } from "../host/Host";
import { useStore } from "../store/Store";


// import { coorB } from "./F";

export const Xarita=()=> {
    const { t } = useTranslation();
const [zoomA, setzoomA]= useState(6);
const poligons=useStore(state=>state.poligons)
const setpoligons=useStore(state=>state.setpoligons)
const [markaz, setMarkaz] = useState({
  lat:41.366080,
  long:69.306381,
  
  
  phoneNumber: "+998781503156",
  
  addressUz: "Tashkent sh,17-mavze sanoat xududi",
  addressRu: "г.Ташкент, 17-я районная промзона",
  addressEn: "Tashkent city, 17th district industrial zone",
  addressKrill: "Ташкент ш,17-мавзе саноат худуди",
  
  titleUz: 'RESPUBLIKA TEXNIK VA AMALIY SPORT TURLARI MARKAZI',
  titleRu: 'Республиканский центр технических и прикладных видов спорта',
  titleEn: 'CENTER OF TECHNICAL AND PRACTICAL SPORTS OF THE REPUBLIC',
  titleKrill: 'РEСПУБЛИКА ТEХНИК ВА АМАЛИЙ СПОРТ ТУРЛАРИ МАРКАЗИ',
  
})
const [showModalRegion, setShowModalRegion] = useState(false)
const [region, setRegion] = useState(null)
  const [center, setcenter] = useState([41.327178,64.192237]);
  

 
  const mapRef = useRef(null);





  const getRegions = (ymaps) => {
    if (mapRef && mapRef.current) {
      var objectManager = new ymaps.ObjectManager();
      ["001"].map(item=>{
     
        ymaps.borders
        .load(item, {
    quality:3,
    lang:'uz'
        })
        .then(function(result) {
            
          // Очередь раскраски.
          var queue = [];
          // Создадим объект regions, где ключи это ISO код региона.
          var regions = result.features.reduce(function(acc, feature) {
            // Добавим ISO код региона в качестве feature.id для objectManager.
            var iso = feature.properties.iso3166

            feature.id = iso;
            // Добавим опции региона по умолчанию.
            feature.options = {
              fillOpacity: 1,
              strokeColor: "#FFF",
              strokeOpacity: 0
            };
            acc[iso] = feature;
            return acc;
          }, {});
          // Функция, которая раскрашивает регион и добавляет всех нераскрасшенных соседей в очередь на раскраску.
          function paint(iso) {
          
            // Получим ссылку на раскрашиваемый регион и на его соседей.
            var region = regions[iso];
            
            // Раскрасим регион в первый доступный цвет.
          
            if(region.id==="UZ"){
       
                region.options.fillColor = "#f330";
                region.options.strokeColor = "#46513f";
                region.options.strokeOpacity = "0";
                region.options.strokeWidth = "1";
                
            }else{
                region.options.fillColor = "#fbeedc";
                region.options.strokeOpacity = "0";
                region.options.strokeWidth = "3";
            }
            
          }
          for (var iso in regions) {
            // Если регион не раскрашен, добавим его в очередь на раскраску.
            if (!regions[iso].options.fillColor) {
              queue.push(iso);
            }
            // Раскрасим все регионы из очереди.
            while (queue.length > 0) {
              paint(queue.shift());
            }
          }
          // Добавим регионы на карту.
          result.features = [];
          for (var reg in regions) {
            result.features.push(regions[reg]);
          }
          objectManager.add(result);
          mapRef.current.geoObjects.add(objectManager);
        })});
   
    
    }
  };

  
  useEffect(() => {
  
    if(window.screen.width<1600 && window.screen.width>1025){
      setzoomA(6)
          }
    if(window.screen.width<1024){
      setzoomA(4)
    }
    getDataPoligon()
   }, []);
const getDataPoligon=()=>{
  axios.get(`${api}/GetApi/GetPoligons/`).then(res=>{
    setpoligons(res.data)
  })
}
  const openModalRegion=(item)=>{
      setcenter([item.lat, item.long])
     setRegion(item)
     setShowModalRegion(true)
  }

  return (
    <div>
      <br/>
      <br/>
      <br/>
      <h1 className='title'>
{t("Lokatsiya")}
        </h1>
    
    <div className="mapR">
      <div className="poligons">
      <div key={-1} onClick={()=>{setcenter([markaz.lat, markaz.long]);if(window.screen.width<1024){
            openModalRegion(markaz);} 
            setzoomA(11);}} className="poligons_item">
              {markaz[`title${t("lang")}`]}
          </div>
        {poligons!==null?poligons.map((info, index) => {
          return(<div key={index} onClick={()=>{setcenter([info.lat, info.long]); if(window.screen.width<1024){
            openModalRegion(info);}
             setzoomA(11); }} className="poligons_item">
              {info[`title${t("lang")}`].toUpperCase()}
          </div>)
        }):<></>}
        
      </div>
    
        <div className="mapRT">
        <div className="mapRT_item">
     
        <YMaps
       
        query={{
      lang: t("check")?"uz":"ru"
    }}>
            <Map
   
         instanceRef={mapRef!==null?mapRef:{}}
  
       
         onLoad={ymaps => getRegions(ymaps)}
        options={{
          minZoom:4,
          restrictMapArea:5
        }}
        className="xar"
         modules={["borders", "ObjectManager"]}
              width="100%"
              height="100%"
              state={{
                center: center,
                zoom:zoomA,
               }}>
             
             

             <Clusterer
              options={{
                groupByCoordinates: true,
                preset: "islands#invertedNightClusterIcons",
              
              }}
             
            >
 <Placemark
                    onClick={()=>{openModalRegion(markaz)}}
                       key={100}
                       className="placeMark"
                       geometry={
                          [markaz.lat, markaz.long]
                       }
                      
                       options={{
                         preset:"islands#redDotIcon",
                  
   
                         strokeColor: "#F008"
                       }}
                       properties={{
                        hintContent: markaz[`title${t("lang")}`],
                        iconContent: markaz[`title${t("lang")}`],
                         balloonContent: '<img src="http://img-fotki.yandex.ru/get/6114/82599242.2d6/0_88b97_ec425cf5_M" />',
                       }}
                       modules={["geoObject.addon.hint"]}
                       
                     />

           </Clusterer>
              
                      <Clusterer
                       options={{
                         groupByCoordinates: false,
                         preset: 'islands#invertedDarkGreenClusterIcons',
                       
                       }}
                      
                     >
                        {poligons!==null?poligons.map((info, index) => {
                         return (
                         <Placemark
                          onClick={()=>{openModalRegion(info);}}
                             key={index}
                             className="placeMark"
                             geometry={[info.lat, info.long]}
                            
                             options={{
                               preset: 'islands#darkGreenDotIcon',
                        
         
                               strokeColor: "#F008"
                             }}
                             properties={{
                              hintContent: info[`title${t("lang")}`],
                              iconContent: info[`title${t("lang")}`],
                               balloonContent: '<img src="http://img-fotki.yandex.ru/get/6114/82599242.2d6/0_88b97_ec425cf5_M" />',
                             }}
                             modules={["geoObject.addon.hint"]}
                             
                           />
                        
                         );
                       }):''}
                     </Clusterer>
              
          
              
              
              {/* <GeolocationControl options={{ float: "left" }} /> */}
              {/* <TypeSelector options={{ float: "right" }} /> */}
              {/* <TrafficControl options={{ float: "right" }} /> */}
             
              <ZoomControl constraints={[4, 10]} options={{ float: "left" }} />
              {/* <FullscreenControl/> */}
              {/* <RulerControl/> */}
            </Map>
          </YMaps>
        </div>
          <Modal  centered show={showModalRegion} onHide={() => setShowModalRegion(false)}>

              {region!==null?<div className="map-modal_content" >
            <div className="modal_x" onClick={()=>{ setShowModalRegion(false)}}><IoIosCloseCircle/></div>
                            <h2 className="region_name">{region[`title${t("lang")}`]}</h2>
                            <ul className="region_info">
                                <li>
                                    <strong><FaBuilding className="icon" size="1.8rem" color="#3D4A36" cursor="pointer"/></strong>
                                    {region[`address${t("lang")}`]}
                                </li>
                                <li>
                                    <strong> <BsTelephoneFill className="icon" size="1.8rem" color="#3D4A36" cursor="pointer"/></strong>
                                    {region.phoneNumber}
                                </li>
                             
                                
                               
                                <li>
                                <strong> <HiOutlineLocationMarker className="icon" size="2.2rem" color="#3D4A36" cursor="pointer"/></strong>
                                   
                                    <a target="_blank" style={{color:"#3D4A36"}} href={`https://yandex.uz/maps/?ll=${region.long}%2C${region.lat}8&mode=search&sll=${region.long}%2C${region.lat}&text=${region.lat}%2C${region.long}`}>{t("Xaritada ko'rish")}</a>
                                </li>
                                
                                
                            </ul>

                        </div>:<></>}
                        </Modal>
        
        </div>
       
     
    </div></div>
  );
}

































