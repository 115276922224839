import React, { useState } from 'react'
import logo from '../assets/logo.svg'
import pdf from '../assets/pdf.svg'
import { useTranslation } from 'react-i18next'
export default function About() {
    const {t} =useTranslation()
  return (
    <div className='about'>
     <div className='about_img'>
        <img src={logo}/>
        <p>{t("logo_text")}</p>
     </div>
     <div className='about_text'>
        <h1 className='title'>{t("Biz haqimizda")}</h1>
<p>{t("about_text_1")}</p>
<p>{t("about_text_2")}</p>
<p>{t("about_text_3")}</p>
<p>{t("about_text_4")}</p>
<p>{t("about_text_5")}</p>
<p>{t("about_text_6")}</p>
<a target='_blank' href="https://caliber.uz/Files/nizom.docx" className='btnGreen'>
    <div className='btn_img'></div>
    <span>{t("Nizomni yuklash")}</span>
</a>    
     </div>
    </div>
  )
}
