import React from 'react'
import { useEffect, useState } from 'react';
import { Image } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Link, Navigate, useParams } from 'react-router-dom';
import { MdVisibility } from 'react-icons/md';
import { MdOutlineDateRange } from 'react-icons/md';
import { FcShare } from 'react-icons/fc';
import { FaTelegramPlane, FaFacebookF } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import "swiper/css";
import "swiper/css/pagination";

import { motion } from "framer-motion"
import "swiper/css/navigation";
import "./assets/OpenNews.css";
import { useCookies } from 'react-cookie';
import { FacebookShareButton, TelegramShareButton } from 'react-share';
import { api } from '../host/Host';
import axios from 'axios';




function OpenNews() {
    const { t } = useTranslation();
    const [data, setData] = useState({})
    const [error, setError] = useState(false)
    const [newsUz, setnewsUz] = useState([])
  
    const [cookies, setCookie] = useCookies([]);
    const params = useParams();
    const { id } = params;
    
    const [idT, setidT] = useState(id)
    const [elem, setelem] = useCookies([`${id}`])

  
    //    @ts-ignore
    const getOpenNews=(id)=>{
        setCookie(id, id, {
            maxAge: 300 
         });
      
        axios.get(`${api}/GetApi/GetNewsById/?id=${id}&seen=${elem.hasOwnProperty(`${id}`)?true:false}`)
        .then((res)=>{
          
            var config=res.data
          var textUz=config.mainTextUz  
          var textRu=config.mainTextRu
          var date=config.newsDate.substring(0, 10)
          var d=date.substring(8, 10)+'.'+date.substring(5, 7)+'.'+date.substring(0, 4)
          config.newsDate=d
          var Uz=""  
          var Ru=""  
          if(textUz!==null){
              textUz.split("</p>\r\n\r\n<p>&nbsp;</p>\r\n\r\n<p>").map((item)=>{
                  Uz+=item+"<br/><br/>"

                              })
                              textUz=""
                              Uz.split("</p>\r\n\r\n<p>").map((item)=>{
              
                                  textUz+=item+"<br/>"
                                              })
                                              config.mainTextUz=textUz
          }
          if(textRu!==null){

              textRu.split("</p>\r\n\r\n<p>&nbsp;</p>\r\n\r\n<p>").map((item)=>{
                  Ru+=item+"<br/><br/>"
                              })
                              
                              textRu=""

              Ru.split("</p>\r\n\r\n<p>").map((item)=>{
                  textRu+=item+"<br/>"
                              })

                                                              
                                                              config.mainTextRu=textRu
          }
         
          setData(config);})
         .catch(e =>setError(true))
         axios.get(`${api}/GetApi/GetNews/`)
         .then((res) => {
         
            var b=[]
            res.data.map((item)=>{
                var x=item
                x.newsDate=x.newsDate.substring(8, 10)+'.'+x.newsDate.substring(5, 7)+'.'+x.newsDate.substring(0, 4)
                b.push(x)
                
                            })   
            var f=[]
            for(let i=0; i<b.length; i++){
                if(b[i].titleUz!==null){
f.push(b[i])
                }
            }
          
            setnewsUz(f.slice(0,6))
          
      }).catch(e => console.log(e))
  }
    useEffect(() => {
        if(window.location.href[window.location.href.length-1]==="/"){
            var linkR=window.location.href
              window.location.href=linkR.slice(0, linkR.lastIndexOf("/"))  }
       var id=window.location.href
       var g=idT
      
       window.localStorage.setItem("idH", idT)
if(window.location.href.indexOf("?fbclid")===-1){
if(!(/^[\wа-я]*$/.test(id.split('/')[id.split('/').length-1]))){
        window.location.href=api+'/error'

     }
}
      
        getOpenNews(g)
    }, []);
    return (

        <motion.div initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}  transition={{ duration: 1.1 }} className="open-news" style={{padding:'50px 18%'}}>
          
            {
            error?<Navigate to="/error" />:
            <div className="container" >
                <Row >
<Col lg={17} md={24} sm={24}>

<div className="open-news-title">
                    {Object.keys(data).length !== 0 &&
                      <h5>{data[`title${t('lang')}`]}</h5>
      
                    }
                </div>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    
                <div className="open-news-visites">
                <a target="_blank" >
                        <p><FcShare className='icon' size='1.4rem' /></p>
                        {Object.keys(data).length !== 0 &&
                            <p className="yoz"></p>
                        }
                    </a>
                    <FacebookShareButton url={`${api}/allnews/${t('lang') === 'ru'?"ru/":''}${data.id}`}
                    quote={t('lang') === 'uz' ? data.titleUz : data.titleRu}
                    // description={t('lang') === 'uz' ? data.titleUz : data.titleRu}
                    hashtag={"#vatanparvar"}
                    className="Demo__some-network__share-button"
                    >
                    <a className="date">
                        <p><FaFacebookF className='icon' size='1.4rem' /></p>
                        {Object.keys(data).length !== 0 &&
                            <p className="yoz"></p>
                        }
                    </a>
                    </FacebookShareButton>
                    <TelegramShareButton url={`${api}/allnews/${t('lang') === 'ru'?"ru/":''}${data.id}`}
                    title={data[`title${t('lang')}`]}
                    // description={t('lang') === 'uz' ? data.titleUz : data.titleRu}
                    className="Demo__some-network__share-button"
                    >
                <a target="_blank" className="date">
                        <p><FaTelegramPlane className='icon' size='1.4rem' /></p>
                        {Object.keys(data).length !== 0 &&
                            <p  className="yoz"></p>
                        }
                    </a>
                    </TelegramShareButton>
                    </div>
                    <div className="open-news-visites">
                    <div className="date">
                        <p><MdOutlineDateRange className='icon' size='1.4rem' /></p>
                        {Object.keys(data).length !== 0 &&
                            <p>{data.newsDate.substring(0, 10)}</p>
                        }
                    </div>
                    <div className="visites">
                        <p><MdVisibility className='icon' size='1.4rem' /></p>
                        {Object.keys(data).length !== 0 &&
                            <p>{data.visits}</p>
                        }
                    </div>


                </div>
                
                </div>
                <Image.PreviewGroup>
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              loop={true}
              navigation={true}
              className="mySwiper"
            >
              <SwiperSlide>
              <div
                        className="swiper-slide_body"
                       style={{
                     
                          display:'flex',
                          alignItems:'center',
                          justifyContent:'center',
                    
                          
                       }}
                      >
                        <img className='red' style={{height:'auto', width:"100%" }} src={`${api}/Files/News/${data.titleImage}`}/>  
                      </div>
                      </SwiperSlide>
                  
                  {data.newsImage && data.newsImage.length!==0?data.newsImage.map((item, index)=>{
                      return(<SwiperSlide><div
                        className="swiper-slide_body"
                        style={{
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center',
                            
                    
                        
                           


                        }}
                      >
                             <img className='red' style={{height:'auto', width:"100%"}} src={`${api}/Files/News/${item.imageUrl}`}/>  
                      </div>
                       </SwiperSlide>)

                  }):''}
                
             
             
              
              
            </Swiper>

                <div className="open-news-text">
                    {Object.keys(data).length !== 0 &&
                        <p>
                            <p className='salomText' style={{textAlign:'justify', lineHeight:"2", fontSize:'18px', textIndent:'50px'}} dangerouslySetInnerHTML={{__html: data[`mainText${t('lang')}`]}}>

                            </p>
                        </p>
                    }
                </div>
            </Image.PreviewGroup>
    </Col>
    <Col lg={7} md={0} sm={0} className="newTabCol">
   <ul className="newTab">
       <h4>{t("So'ngi yangiliklar")}</h4>
       
       {newsUz!==null?newsUz.map((item, key)=>{
           return( <li onClick={()=>{getOpenNews(item.id)}}>
           <Link to={t('check')?"/allnews/" + item.id:"/allnews/ru/" + item.id} className="news_list-item1" key={key}>
            <div className='icons'>
            <p className='tabDate'><MdOutlineDateRange className='icon' size='1rem' />{
                                        item.newsDate.substring(0, 10)
                                    }</p>
            <p className='tabDate'>{
                                        item.visits
                                    }<MdVisibility className='icon' size='1rem' /></p>
            </div>   
            <div className='titles'>             
            <p className='tabTitle' style={{textTransform:'uppercase'}}>{item[`title${t('lang')}`]}</p>
            </div></Link>
            </li>)
       }):<></>}
  
   </ul>
        </Col>

                </Row>
      
<br/>
<br/>

            </div>}
          
        </motion.div>
    )
}

export default OpenNews;