import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../store/Store'
import { Modal } from 'antd'
import axios from 'axios'
import { api } from '../host/Host'
import {FadeLoader } from 'react-spinners'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
export default function Stattistic() {
    const {t} = useTranslation()
    const poligons=useStore(state=>state.poligons)
    const members=useStore(state=>state.members)
    const setmembers=useStore(state=>state.setmembers)
    const count=useStore(state=>state.count)
    const setcount=useStore(state=>state.setcount)
    const membersSearch=useStore(state=>state.membersSearch)
    const setmembersSearch=useStore(state=>state.setmembersSearch)
    const [membersCount, setmembersCount]=useState(0)
 
    const [page, setpage]=useState(0)
    const [number, setnumber]=useState(0)
    const [start, setstart]=useState(0)
    const [open, setopen]=useState(false)
    const [search, setsearch]=useState(false)
    const [loader, setloader]=useState(false)
    const [searchText, setsearchText]=useState("")
    useEffect(()=>{
      getMembers(page, "")
    }, [])

    const getMembers=(pageA, sText)=>{
 
      if(sText.length===0){
        if(!members[pageA]){
          setloader(true)
          axios.get(`${api}/GetApi/GetMembers?page=${pageA+1}`).then(res=>{
            setmembers(res.data.data, pageA)
            setmembersCount(res.data.count)
            setcount(res.data.count)
            setsearch(false)
            setloader(false)
            setnumber(number+1)
            }).catch(err=>{
            console.log(err)
          })
        }else{
          setsearch(false)
          setmembersCount(count)
          setnumber(number+1)

        }
      }else{
        setloader(true)
        axios.get(`${api}/GetApi/GetMembers?page=${pageA+1}&search=${sText}`).then(res=>{
          setmembersSearch(res.data.data, pageA)
          setmembersCount(res.data.count)
          setsearch(true)
          setloader(false)
          setnumber(number+1)
         }).catch(err=>{
          console.log(err)
        })
      
    }
      
     }
     const searchFunc=(sText)=>{
             setpage(0)
             setstart(0)
             getMembers(0, sText)
             setmembersSearch([])
     }
  return (
    <div className='stat'>
       
        <h1 className='title'>{t("A’ZOLAR VA POLIGONLAR SONI")}</h1>
        <div className='stat_box'>
        <div className='stat_item'>
        <div>
                <h1>{t("Poligonlar")}</h1>
                <p>{poligons!==null?poligons.length:""}</p>
                </div>
                <a href="#location" className='btnGreen btngallery'>
    <div className='btn_img'></div>
    <span>{t("Ko'rish")}</span>
</a>
            </div>
            <div className='stat_item'>
              <div>
                <h1>{t("A’zolar")}</h1>
                <p>{count}</p>
                </div>
        <button onClick={() => setopen(true)} className='btnGreen btngallery'>
    <div className='btn_img'></div>
    <span>{t("Ko'rish")}</span>
</button>
            </div>
            
        </div>
        <Modal
        title={t("A'zolar ro'yhati")}
        centered
        open={open}
        footer={false}
        onCancel={() => setopen(false)}
        width={1000}
      >
        <div className='members'>
       
          <div className='membersInput'>
          <input placeholder={t("QIDIRISH")+"..."} value={searchText} onChange={(e)=>{setsearchText(e.target.value.toUpperCase())}}/>
          <button onClick={()=>searchFunc(searchText)} className='btnGreen btngallery'><span>{t("QIDIRISH")}</span></button>
          </div>
      
      <div className='membersDiv'>
      {loader?
        <div className='membersLoader'>
          <FadeLoader  color="#3D4A36" />
        </div>
        :<></>}
      <table>
        <thead>
        <tr>
          <th style={{textAlign:'center'}}>{t("T/r")}</th>
          <th>{t("F.I.SH")}</th>
        </tr>
        </thead>
       <tbody>
       {!search?members!==null && !!members[page]?members[page].map((item,key)=>{
          return(<tr key={key}>
            <td  style={{textAlign:'center'}}>{(page*10)+key+1}</td>
            <td>{item.fullname}</td>
          </tr>)
          }):<></>:membersSearch!==null && !!membersSearch[page]?membersSearch[page].map((item,key)=>{
            return(<tr key={key}>
              <td  style={{textAlign:'center'}}>{(page*10)+key+1}</td>
              <td>{item.fullname}</td>
            </tr>)
            }):<></>}
       </tbody>
       
       </table>
       
       </div>
       {membersCount!==0?<div className='paginations'>

{start>0?<div onClick={()=>{setpage(page-1); setstart(start-1); getMembers(page-1, searchText)}} className='pag_item left_arrow'><IoIosArrowBack/></div>:<></>}
    {Math.round(membersCount/10)>1?new Array(Math.round(membersCount/10)).fill(null).slice(0,6).map((item,key)=>{
        return( <div key={key} className={`pag_item ${page===key+start?"active_page":""}`} onClick={()=>{setpage(key+start); getMembers(key+start, searchText)}}>{key+1+start}</div>)
    }):<></>}
{start<Math.round(membersCount/10)-6?<div onClick={()=>{setpage(page+1); setstart(start+1); getMembers(page+1, searchText)}} className='pag_item left_arrow'><IoIosArrowForward/></div>:<></>}


</div>:<></>}
       </div>
      </Modal>
    
    </div>
  )
}
