import React from 'react'
import logo from '../assets/logo.svg'
import tel from '../assets/tel.svg'
import email from '../assets/email.svg'
import instagram from '../assets/instagram.svg'
import telegram from '../assets/telegram.svg'
import facebook from '../assets/facebook.svg'
import { useTranslation } from 'react-i18next'
import { Nav } from 'react-bootstrap'
import { motion } from "framer-motion"
export default function Footer() {
    const {t} = useTranslation()
  return (
    <motion.div initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}  transition={{ duration: 1.5 }} className='footer'>
        <div className='footer_left'>
        <Nav className="footer_nav">
            <Nav.Link href="#about" className="link">{t("Biz haqimizda")}</Nav.Link>
            <Nav.Link href="#satatistics"  className="link">{t("Statistika")}</Nav.Link>
            <Nav.Link href="#gallery"  className="link">{t("Galereya")}</Nav.Link>
            <Nav.Link href="#tir"  className="link">{t("ONLINE TIR")}</Nav.Link>
            <Nav.Link href="#faq"  className="link">{t("FAQ")}</Nav.Link>
            <Nav.Link href="#location"  className="link">{t("Lokatsiya")}</Nav.Link>
           </Nav>
        </div>
        <div className='footer_center'>
          <h1>{t("footer")}</h1>
          <a href="tel: +998 71 200 0000"><img src={tel}/> <span>+998 71 200 0000</span></a>
        <p>{t("time")}</p>
        </div>
        <div className='footer_right'>
        <div className='footer_brand'>
           <a className='brand_link' href="#" dangerouslySetInnerHTML={{__html:t("brand")}}/>
            <img src={logo}/>
          </div>
          <div className='footer_links'>
                <a href="http://t.me/+eKf4q_YAknNlZTYy" target='_blank'> <img src={telegram}/></a>
                <a href="#" target='_blank'> <img src={facebook}/></a>
              </div>
          <a className='vitc' href="https://vitc.uz/" target="_blank">{t("© 2020 - 2023 “Vatanparvar” axborot texnologiyalari markazi")}</a>
        </div>
    </motion.div>
  )
}
