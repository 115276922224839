import React, { useState } from 'react'
import gallery_right from '../assets/gallery_right.svg'
import gallery_center from '../assets/gallery_center.svg'
import gallery_left from '../assets/gallery_left.svg'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import axios from 'axios'
import { api } from '../host/Host'
import {Link} from 'react-router-dom'
import { useStore } from '../store/Store'
export default function Gallery() {
 const {t}=useTranslation()
 const gallery=useStore(state=>state.gallery)
 const setgallery=useStore(state=>state.setgallery)
  useEffect(()=>{
  axios.get(`${api}/GetApi/GetPhotos/`).then(res=>{
    axios.get(`${api}/GetApi/GetPhotos/`).then((res) => {
			var length=Math.ceil(res.data.length/4)
			var a=[]
			a[0]=res.data.slice(0, length)
			a[1]=res.data.slice(length, length+length)
			a[2]=res.data.slice(2*length, length*3)
			a[3]=res.data.slice(3*length, res.data.length)
			setgallery(a)
		}).catch(e => console.log(e))
  }).catch(err=>{
    console.log(err)
  })
  },[])
    return (
    <div className='gallery' >
        <div className='gallery_imgs'>
            <div>
            <img className='gallery_right' src={gallery_right}/>
            <img className='gallery_center' src={gallery_center}/>
            <img className='gallery_left' src={gallery_left}/>
            </div>
        </div>
        <div className='gallery_text'>
            <h1 className='title'>{t("FOTOALBOM")}</h1>
            <p>{t(" it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, ")}</p>
           {gallery!==null && gallery.length!==0?<Link to="/fotoalbom" className='btnGreen btngallery'>
    <div className='btn_img'></div>
    <span>{t("Ko‘rish")}</span>
</Link>  :<></>}
            
        </div>
    </div>
  )
}
