import React from "react";
import { useEffect, useState } from "react";
import { Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { api } from "../host/Host";
import './assets/fotogallery.css'
import axios from "axios";
import { useStore } from "../store/Store";
import { motion } from "framer-motion"
function GalleryImages() {
  const {t} = useTranslation();
  const gallery=useStore(state=>state.gallery)
  const setgallery=useStore(state=>state.setgallery)
  
  useEffect(()=>{
	if(gallery.length===0){
		axios.get(`${api}/GetApi/GetPhotos/`).then((res) => {
			var length=Math.ceil(res.data.length/4)
			var a=[]
			a[0]=res.data.slice(0, length)
			a[1]=res.data.slice(length, length+length)
			a[2]=res.data.slice(2*length, length*3)
			a[3]=res.data.slice(3*length, res.data.length)
			setgallery(a)
		}).catch(e => console.log(e))
	}
}, [])


  return (
	<Image.PreviewGroup>
    <motion.div initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}  transition={{ duration: 1.1 }} className="fotogalereya">

       <br/>
<div className="foto">
<div className="foto_column">
		{!!gallery[0]?gallery[0].map((item,key)=>{
	    return(
				<a key={key} target="_blank" className="foto_link" style={{width:'100%', padding:'0px', height:'auto'}}>
					<figure className="foto_thumb">
						<Image height="100%" src={`${api}/Files/Photogallery/${item.fileName}`} alt={item[`description${t("lang")}`]} className="foto_image img1"/>
						<figcaption className="foto_caption">{item[`description${t("lang")}`]}</figcaption>
					</figure>
				</a>
		)
		}):''}	
	</div>
	<div className="foto_column">
		{!!gallery[1]?gallery[1].map((item,key)=>{		return(
				<a key={key} target="_blank" className="foto_link" style={{width:'100%', padding:'0px', height:'auto'}}>
					<figure className="foto_thumb">
						<Image height="100%" src={`${api}/Files/Photogallery/${item.fileName}`} alt={item[`description${t("lang")}`]} className="foto_image img1"/>
						<figcaption className="foto_caption">{item[`description${t("lang")}`]}</figcaption>
					</figure>
				</a>
		)
		}):''}
		</div>
		<div className="foto_column">
		{!!gallery[2]?gallery[2].map((item,key)=>{
			
			return(
				<a key={key} target="_blank" className="foto_link" style={{width:'100%', padding:'0px', height:'auto'}}>
					<figure className="foto_thumb">
						<Image height="100%" src={`${api}/Files/Photogallery/${item.fileName}`} alt={item[`description${t("lang")}`]} className="foto_image img1"/>
						<figcaption className="foto_caption">{item[`description${t("lang")}`]}</figcaption>
					</figure>
				</a>
		)
		}):''}	
	</div>
	<div className="foto_column">
		{!!gallery[3]?gallery[3].map((item,key)=>{
			
			return(
				<a key={key} target="_blank" className="foto_link" style={{width:'100%', padding:'0px', height:'auto'}}>
					<figure className="foto_thumb">
						<Image height="100%" src={`${api}/Files/Photogallery/${item.fileName}`} alt={item[`description${t("lang")}`]} className="foto_image img1"/>
						<figcaption className="foto_caption">{item[`description${t("lang")}`]}</figcaption>
					</figure>
				</a>
		)
		}):''}	
	</div>

      </div>
    </motion.div></Image.PreviewGroup>
  );
}

export default GalleryImages;
