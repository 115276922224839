import { create } from "zustand";


export const useStore=create((set)=>({
    poligons:null,
    setpoligons:(value)=>set((state)=>({poligons:value})),
    gallery:[],
    setgallery:(value)=>set((state)=>({gallery:value})),
    count:0,
    setcount:(value)=>set((state)=>({count:value})),
    jobs:null,
    setjobs:(value)=>set((state)=>({jobs:value})),
    members:[],
    setmembers:(value, page)=>set((state)=>{
        var a=state.members
        a[page]=value
        console.log(a)
        return({members:a})
    }),
    membersSearch:[],
    setmembersSearch:(value, page)=>set((state)=>{
        var a=state.membersSearch
        a[page]=value
        console.log(a)
        return({membersSearch:a})
    }),
    nav:'about',
    setnav:(value)=>set((state)=>({nav:value})),
    Categories:null,
    setCategories:(value)=>set((state)=>({Categories:value})),
   
}))